<template>
  <v-main>
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <router-view
      style="min-height: calc(100vh - 115px); padding-top: 18px; padding-bottom: 18px;"
    />
    <v-hover
      v-if="showbotton_voltar"
      rounded
    >
      <v-btn
        icon
        fab
        title="Voltar ao topo."
        class="card-icon mx-2 back-to-top"
        elevation="8"
        @click="voltar_topo"
      >
        <v-icon
          color="blue darken-3"
        >
          mdi-arrow-up-bold
        </v-icon>
      </v-btn>
    </v-hover>
    <dashboard-core-footer />
  </v-main>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'DashboardCoreView',
    components: {
      DashboardCoreFooter: () => import('./Footer'),
      // ErrorView: () => import('./ErrorView'),
    },
    data () {
      return {
        showbotton_voltar: false,
      }
    },
    computed: {
      ...mapState(['snackbar']),
    },
    watch: {
      $route (to, from) {
        this.checkVersion()
      },
    },
    mounted () {
      this.checkVersion()
      window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
      checkVersion () {
        const versionApi = localStorage.getItem('version_api')
        const versionApp = localStorage.getItem('version_app')
        if (versionApi) {
          if (versionApp) {
            if (versionApp !== versionApi) {
              localStorage.setItem('version_app', versionApi)
              window.location.reload()
            }
          } else {
            localStorage.setItem('version_app', versionApi)
          }
        }
      },
      handleScroll: function () {
        if (window.scrollY > 50) {
          this.showbotton_voltar = true
        } else {
          this.showbotton_voltar = false
        }
      },
      voltar_topo () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    },
  }
</script>

<style lang="sass">

.back-to-top
    position: fixed
    bottom: 20px
    right: 20px
    z-index: 100

</style>
